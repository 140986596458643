import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/designer',
    name: 'designer',
    component: () => 
      import('../views/DesignerView.vue')
  },
  {
    path: '/order',
    name: 'order',
    component: () => 
      import('../views/OrderView.vue')
  },
  {
    path: '/ordernew',
    name: 'ordernew',
    component: () => 
      import('../views/OrderNew.vue')
  },
  {
    path: '/orderparts',
    name: 'orderparts',
    component: () => 
      import('../views/PartsView.vue')
  },
  {
    path: '/partnew',
    name: 'partnew',
    component: () => 
      import('../views/PartsNew.vue')
  },
  {
    path: '/complements',
    name: 'complements',
    component: () => 
      import('../views/ComplementsView.vue')
  },
  {
    path: '/complementnew',
    name: 'complementnew',
    component: () => 
      import('../views/ComplementsNew.vue')
  },
  {
    path: '/budget',
    name: 'budget',
    component: () => 
      import('../views/budgetView.vue')
  },
  {
    path: '/shipping',
    name: 'shipping',
    component: () => 
      import('../views/shippingInfoView.vue')
  },
  {
    path: '/admin',
    name: 'admin',
    component: () => 
      import('../views/AdminView.vue')
  },
  {
    path: '/adminhome',
    name: 'adminhome',
    component: () => 
      import('../views/AdminHomeView.vue')
  },
  {
    path: '/adminusers',
    name: 'adminusers',
    component: () => 
      import('../views/AdminUserListView.vue')
  },
  {
    path: '/adminusernew',
    name: 'adminusernew',
    component: () => 
      import('../views/AdminUserView.vue')
  },
  {
    path: '/adminproducts',
    name: 'adminproducts',
    component: () => 
      import('../views/AdminProductListView.vue')
  },
  {
    path: '/productnew',
    name: 'productnew',
    component: () => 
      import('../views/AdminProductView.vue')
  },
  {
    path: '/erroraccess',
    name: 'erroraccess',
    component: () => 
      import('../views/ErrorAccess.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router

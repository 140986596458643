<template>
  <!-- <NavigationComp /> -->
  <router-view v-slot="{ Component }">
		<!-- <transition name="fadeUp" mode="out-in"> -->
			<component :is="Component" :key="$route.path"></component>
		<!-- </transition> -->
	</router-view>	
  <!-- <FooterNav /> -->
</template>

<script>
// import NavigationComp from '@/components/NavigationComp.vue';
// import FooterNav from '@/components/FooterNav.vue'

export default {
    Name: "App",
    components: {
}
};
</script>

<style>
    #app {
      color: #2c3e50;
    }
    .fadeUp-enter-active,
    .fadeUp-leave-active {
        transition: opacity 0.25s, transform 0.25s;
    }
    .fadeUp-enter,
    .fadeUp-leave-to {
        opacity: 0;
        transform: translateY(30%);
    }
    @keyframes loading {
      from {
        background-position: -65px 0, 0 0, 0 190px, 50px 195px;
      }
      to {
        background-position: 65px 0, 0 0, 0 190px, 50px 195px;
      }
    }
</style>
